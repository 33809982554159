// import { useIsAuthenticated } from "@azure/msal-react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

const PrivateRoute = () => {
  // const isAuth = useIsAuthenticated();
  const AuthDuck = useSelector((state) => state.AuthDuck);

  const { userInfo } = AuthDuck;

  if (
    userInfo.token &&
    dayjs(jwtDecode(userInfo?.token).exp * 1000) < dayjs()
  ) {
    console.log("Token expired");
    return <Navigate to="/sign-in" />;
  }

  return userInfo.token ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default PrivateRoute;
