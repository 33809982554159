import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import AddQaQueueToCampaign from "./Components/campaigns/AddQaQueueToCampaign";
import {
  getQaCampaignQueues,
  RESET_CAMPAIGN_QA_QUEUES,
  editQaCampaignQueue,
  updateQaCampaignDefaultQueue,
} from "./State/CampaignQueuesDuck";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const QaQueueManage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const [viewInactive, setViewInactive] = React.useState(false);

  useEffect(() => {
    dispatch({ type: RESET_CAMPAIGN_QA_QUEUES });
    dispatch(getQaCampaignQueues({ id: id }));
  }, [dispatch, id]);

  const campaignQueues = useSelector((state) => state.campaignQueues);

  const { campaignQueuesList, campaignQueuesSuccess } = campaignQueues;

  const activeCampaignQueues = campaignQueuesList?.filter(
    (campaignQueue) => campaignQueue.active === true,
  );
  const inactiveCampaignQueues = campaignQueuesList?.filter(
    (campaignQueue) => campaignQueue.active === false,
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <AddQaQueueToCampaign
          campaignQueuesList={activeCampaignQueues}
          id={id}
        />
        <Button
          onClick={() => {
            navigate("/CDA/QaConsole/QaQueues");
          }}
          variant="contained"
          color="secondary"
        >
          View All Qa Queues
        </Button>
      </Stack>
      {activeCampaignQueues && activeCampaignQueues.length > 0 && (
        <QueueTable list={activeCampaignQueues} />
      )}

      {campaignQueuesList && campaignQueuesList.length > 0 && (
        <Button
          onClick={() => setViewInactive(!viewInactive)}
          color="inherit"
          sx={{ mt: 3 }}
        >
          {viewInactive ? "Hide Inactive" : "View Inactive"}
        </Button>
      )}

      {viewInactive && <QueueTable list={inactiveCampaignQueues} />}

      <AlertPopup
        open={campaignQueuesSuccess}
        message="Qa Queue Added Successfully"
      />
    </Stack>
  );
};

export default QaQueueManage;

const QueueTable = ({ list }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Queue Type</StyledTableCell>
            <StyledTableCell>Default</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row?.qa_queue?.queue_name}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row?.qa_queue?.queue_type}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.primary ? <CheckCircleIcon color="primary" /> : ""}
              </StyledTableCell>
              <StyledTableCell component="th" align="right" scope="row">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  <MakePrimaryQueueForCampaign QaQueue={row} />
                  <RemoveQueueFromCampaign
                    QaQueue={row}
                    activate={!row.active}
                  />
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const RemoveQueueFromCampaign = ({ QaQueue, activate }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {

  // }, []);

  return (
    <div>
      <Button color={activate ? "success" : "error"} onClick={handleClickOpen}>
        {activate ? "Activate" : "Deactivate"}
      </Button>
      <Dialog
        sx={{ border: "4px solid red " }}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {activate
            ? "Activate Qa Queue"
            : "Are you sure you want to remove Queue from Campaign?"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              queue_id: QaQueue.queue_id,
              campaign_id: QaQueue.campaign_id,
              active: activate,
            }}
            onSubmit={(values) => {
              dispatch(editQaCampaignQueue(values));
            }}
          >
            {() => (
              <Form>
                <DialogContent>{QaQueue.qa_queue.queue_name}</DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color={activate ? "success" : "error"}>
                    {activate ? "Activate" : "Remove"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const MakePrimaryQueueForCampaign = ({ QaQueue }) => {
  const [open, setOpen] = React.useState(false);
  // console.log("MakePrimaryQueueForCampaign QaQueue: ", QaQueue);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {

  // }, []);

  return (
    <div>
      <Button onClick={handleClickOpen}>
        {QaQueue.primary ? "" : "Make Default"}
      </Button>
      <Dialog
        sx={{ border: "4px solid red " }}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Change to Default Qa Queue for Campaign</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              queue_id: QaQueue.queue_id,
              campaign_id: QaQueue.campaign_id,
              primary: QaQueue.primary,
            }}
            onSubmit={(values) => {
              dispatch(updateQaCampaignDefaultQueue(values)).then(() => {
                dispatch(getQaCampaignQueues({ id: QaQueue.campaign_id })); // Refresh data
                setOpen(false);
              });
            }}
          >
            {() => (
              <Form>
                <DialogContent>{QaQueue.qa_queue.queue_name}</DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Confirm
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};
