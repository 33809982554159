import { Stack, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import axios from "axios";
import DeleteUserfromNotifications from "./Components/DeleteUserfromNotifications ";
import AddMembers from "./Components/AddMembers ";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

const NotificationsGroup = () => {
  const { id: campaignId } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalOpen2, setModalOpen2] = React.useState(false);

  const [selectedRow, setSelectedRow] = React.useState(null);
  const queryClient = useQueryClient();

  const columns = [
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "active",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setSelectedRow(params.row);
            setModalOpen(true);
          }}>
          <DeleteIcon color="error" />
        </Button>
      ),
    },
  ];

  const getAgent = useQuery({
    queryKey: ["notifications", campaignId],
    queryFn: () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/QaInbound/admin/Notificatinsgroup/${campaignId}/notifications`
        )
        .then((res) => res.data.data),
  });

  const Agents = getAgent.data || [];

  const existingEmails = Agents.map((agent) => agent.email) || [];

  const rows = Agents.map((agent, index) => ({
    ...agent,
    id: agent.id || index,
  }));

  const AddMember = useMutation({
    mutationFn: (notifications) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Notificatinsgroup/${campaignId}/notifications`,
        notifications
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
    onError: () => {},
  });

  const DeleteMember = useMutation({
    mutationFn: (email) =>
      axios.delete(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Notificatinsgroup/${campaignId}/notifications`,
        { data: { email } }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
    onError: () => {},
  });

  const handleSubmit = (data) => AddMember.mutate(data);
  const handleDelete = (email) => DeleteMember.mutate(email);

  return (
    <Stack>
      <Typography variant="h6" sx={{ my: 2 }} color="GrayText">
        Notification Group
      </Typography>

      <Button variant="outlined" onClick={() => setModalOpen2(true)}>
        ADD Member
      </Button>

      <Typography variant="h6" sx={{ my: 2 }} color="GrayText">
        List of Email addresses that will receive notifications
      </Typography>
      <DataGridPremium
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id}
      />

      <DeleteUserfromNotifications
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        row={selectedRow}
        campaignId={campaignId}
        onDelete={handleDelete}
      />

      <AddMembers
        open={modalOpen2}
        onClose={() => setModalOpen2(false)}
        row={selectedRow}
        campaignId={campaignId}
        onSave={handleSubmit}
      />

      <AlertPopup
        open={DeleteMember.isSuccess}
        severity="success"
        message="Member deleted successfully"
      />
      <AlertPopup
        open={DeleteMember.isError}
        severity="error"
        message="Failed to delete member"
      />

      <AddMembers
        open={modalOpen2}
        onClose={() => setModalOpen2(false)}
        row={selectedRow}
        campaignId={campaignId}
        existingEmails={existingEmails}
        onSave={handleSubmit}
      />

      <AlertPopup
        open={AddMember.isSuccess}
        severity="success"
        message="Member added successfully"
      />
      <AlertPopup
        open={AddMember.isError}
        severity="error"
        message="Failed to add member"
      />
    </Stack>
  );
};

export default NotificationsGroup;
