import axios from "axios";

const initialState = {
  leadSources: {},
  campaignLeadSources: [],
  campaignLeadSource: {},
  loading: false,
  error: false,
  success: false,
  addLeadSourceSuccess: false,
  editLeadSourceSuccess: false,
  deleteLeadSourceSuccess: false,
};

/**
 * SYNC ACTIONS
 *
 */

/**
 * THUNK ACTIONS
 *
 */

const GET_CAMPAIGN_LEAD_SOURCES = "GET_CAMPAIGN_LEAD_SOURCES";
const GET_CAMPAIGN_LEAD_SOURCES_SUCESSS = "GET_CAMPAIGN_LEAD_SOURCES_SUCESSS";
const GET_CAMPAIGN_LEAD_SOURCES_ERROR = "GET_CAMPAIGN_LEAD_SOURCES_ERROR";

const GET_CAMPAIGN_LEAD_SOURCES_BY_CAMPAIGN_ID =
  "GET_CAMPAIGN_LEAD_SOURCES_BY_CLIENT_ID";
const GET_CAMPAIGN_LEAD_SOURCES_BY_CAMPAIGN_ID_SUCCESS =
  "GET_CAMPAIGN_LEAD_SOURCES_BY_CLIENT_ID_SUCCESS";
const GET_CAMPAIGN_LEAD_SOURCES_BY_CAMPAIGN_ID_ERROR =
  "GET_CAMPAIGN_LEAD_SOURCES_BY_CLIENT_ID_ERROR";

const ADD_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID = "ADD_CAMPAIGN_LEAD_SOURCE";
const ADD_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_SUCCESS =
  "ADD_CAMPAIGN_LEAD_SOURCE_SUCCESS";
const ADD_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_ERROR =
  "ADD_CAMPAIGN_LEAD_SOURCE_ERROR";

const EDIT_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID = "EDIT_CAMPAIGN_LEAD_SOURCE";
const EDIT_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_SUCCESS =
  "EDIT_CAMPAIGN_LEAD_SOURCE_SUCCESS";
const EDIT_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_ERROR =
  "EDIT_CAMPAIGN_LEAD_SOURCE_ERROR";
export const RESET_CAMPAIGN_LEAD_SOURCES = "RESET_CAMPAIGN_LEAD_SOURCES";

const DELETE_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID =
  "DELETE_CAMPAIGN_LEAD_SOURCE";
const DELETE_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_SUCCESS =
  "DELETE_CAMPAIGN_LEAD_SOURCE_SUCCESS";
const DELETE_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_ERROR =
  "DELETE_CAMPAIGN_LEAD_SOURCE_ERROR";

const campaignLeadSourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_LEAD_SOURCES_BY_CAMPAIGN_ID: {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    }
    case GET_CAMPAIGN_LEAD_SOURCES_BY_CAMPAIGN_ID_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        campaignLeadSources: action.payload,
      };
    }
    case GET_CAMPAIGN_LEAD_SOURCES_BY_CAMPAIGN_ID_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
      };
    }
    case ADD_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID: {
      return {
        ...state,
        addLeadSourceSuccess: false,
        loading: true,
        success: false,
        error: null,
      };
    }
    case ADD_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_SUCCESS: {
      return {
        ...state,
        addLeadSourceSuccess: true,
        loading: false,
        success: true,
        error: null,
        campaignLeadSources: action.payload,
      };
    }
    case ADD_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_ERROR: {
      return {
        ...state,
        addLeadSourceSuccess: false,
        loading: false,
        success: false,
        error: true,
      };
    }
    case DELETE_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_SUCCESS: {
      return {
        ...state,
        deleteLeadSourceSuccess: true,
      };
    }
    case DELETE_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_ERROR: {
      return {
        ...state,
        error: true,
        deleteLeadSourceSuccess: false,
      };
    }
    case EDIT_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_SUCCESS: {
      return {
        ...state,
        campaignLeadSource: action.payload,
        editLeadSourceSuccess: true,
      };
    }
    case EDIT_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_ERROR: {
      return {
        ...state,
        error: true,
        editLeadSourceSuccess: false,
      };
    }
    case GET_CAMPAIGN_LEAD_SOURCES: {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    }
    case GET_CAMPAIGN_LEAD_SOURCES_SUCESSS: {
      return {
        ...state,
        leadSources: action.payload,
        loading: false,
        success: true,
      };
    }
    case GET_CAMPAIGN_LEAD_SOURCES_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
      };
    }
    case RESET_CAMPAIGN_LEAD_SOURCES: {
      return {
        ...state,
        addLeadSourceSuccess: false,
        editLeadSourceSuccess: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default campaignLeadSourceReducer;

export const getCampaignLeadSourcesByCampaignId =
  (campaignId) => async (dispatch, getState) => {
    dispatch({ type: GET_CAMPAIGN_LEAD_SOURCES_BY_CAMPAIGN_ID });

    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${campaignId}/lead_sources`,
        config,
      );

      dispatch({
        type: GET_CAMPAIGN_LEAD_SOURCES_BY_CAMPAIGN_ID_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({ type: GET_CAMPAIGN_LEAD_SOURCES_BY_CAMPAIGN_ID_ERROR });
    }
  };

export const addCampaignLeadSourceByCampaignId =
  (campaignId, leadSourceData) => async (dispatch, getState) => {
    dispatch({ type: ADD_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID });

    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${campaignId}/lead_sources`,
        leadSourceData,
        config,
      );

      dispatch({
        type: ADD_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_ERROR,
        payload: err,
      });
    }
  };

export const editCampaignLeadSourceByCampaignId =
  (campaignId, leadSourceData) => async (dispatch, getState) => {
    dispatch({ type: EDIT_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID });

    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${campaignId}/lead_sources/${leadSourceData.id}`,
        leadSourceData,
        config,
      );

      dispatch({
        type: EDIT_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: EDIT_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_ERROR,
        payload: err,
      });
    }
  };

export const deleteCampaignLeadSourceByCampaignId =
  (campaignId, leadSourceId) => async (dispatch, getState) => {
    dispatch({ type: DELETE_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID });

    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${campaignId}/lead_sources/${leadSourceId}`,
        config,
      );

      dispatch({
        type: DELETE_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: DELETE_CAMPAIGN_LEAD_SOURCE_BY_CAMPAIGN_ID_ERROR,
        payload: err,
      });
    }
  };

export const getAllLeadSources = () => async (dispatch, getState) => {
  dispatch({ type: GET_CAMPAIGN_LEAD_SOURCES });

  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const leadSources = await axios.get(
      `${process.env.REACT_APP_API_URL}${`/callCentre/lead_sources`}`,
      config,
    );

    dispatch({
      type: GET_CAMPAIGN_LEAD_SOURCES_SUCESSS,
      payload: leadSources.data,
    });
  } catch (err) {
    dispatch({ type: GET_CAMPAIGN_LEAD_SOURCES_ERROR });
  }
};
