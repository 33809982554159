import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import React from "react";
import { useCampaignContext } from "./CampaignContext";

import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";
import NotificationsGroup from "../Notificationsgroup/NotificationsGroup";
import ScoreCardSelector from "../../../../../Admin/components/CallbiSelector";
import * as Yup from "yup";

const InboundCampaignForm = () => {
  const campaign = useCampaignContext();

  const EditInboundCampaign = useMutation({
    mutationFn: async (data) =>
      await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${data.id}`,
        data
      ),
  });

  return (
    <>
      <Card variant="outlined" sx={{ p: 2, my: 2 }}>
        <Typography sx={{ mb: 2 }} variant="h6" color="GrayText">
          Updated Queue Details
        </Typography>

        <Formik
          initialValues={{
            ...campaign,
            name: campaign.name || "",
            description: campaign.description || "",
            type: campaign.type || "",
            callbi_scorecard: campaign.callbi_scorecard || "",

            minEvaluations: campaign.minEvaluations || "",
            maxEvaluations: campaign.maxEvaluations || "",
          }}
          enableReinitialize={true}
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            type: Yup.string().required("Required"),

            minEvaluations: Yup.number().required("Required"),
            maxEvaluations: Yup.number().required("Required"),
          })}
          onSubmit={(values) => {
            EditInboundCampaign.mutate(values);
          }}>
          {() => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextfieldWrapper name="name" label="Name" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldWrapper name="description" label="Description" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldWrapper name="type" label="Type" />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ my: 1 }} variant="h6" color="GrayText">
                      Queue Settings
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <ScoreCardSelector name="callbi_scorecard" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldWrapper
                      type="number"
                      name="minEvaluations"
                      label="Minimum amount of evaluation allocated to Qa consultant at a time"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldWrapper
                      type="number"
                      name="maxEvaluations"
                      label="Maximum amount of evaluation allocated to Qa consultant at a time"
                    />
                  </Grid>
                </Grid>

                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button variant="contained" type="submit" color="secondary">
                    save
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
        <AlertPopup
          open={EditInboundCampaign.isSuccess}
          severity="success"
          message="Campaign Updated"
        />
        <AlertPopup
          open={EditInboundCampaign.isError}
          severity="success"
          message="Error Updating campaign, Please contact administrator"
        />
      </Card>
      <Card variant="outlined" sx={{ p: 2, my: 2 }}>
        <NotificationsGroup />
      </Card>
    </>
  );
};

export default InboundCampaignForm;
