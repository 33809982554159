import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { DialogContent, Typography } from "@mui/material";
import * as Yup from "yup";
import { deleteCampaignLeadSourceByCampaignId } from "../../State/CampaignLeadSourceDuck";

export default function DeleteCampaignLeadSource({ id, campaignLeadSources }) {
  // console.log("DeleteCampaignLeadSource id: ", id);
  // console.log(
  //   "DeleteCampaignLeadSource campaignLeadSources: ",
  //   campaignLeadSources,
  // );
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const toggleViewInactiveLeadSources = (values) => {
    dispatch(
      deleteCampaignLeadSourceByCampaignId(values.campaign_id, values.id),
    );
    setTimeout(() => {
      handleClose();
    }, 1000);
    window.location.reload();
  };

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        <DeleteIcon />
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Deactivate Lead Source</DialogTitle>

        <Formik
          initialValues={{
            campaign_id: campaignLeadSources.campaign_id,
            id: campaignLeadSources.id,
            name: campaignLeadSources.name,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
          })}
          onSubmit={(values) => {
            toggleViewInactiveLeadSources(values);
          }}
        >
          {() => (
            <Form>
              <DialogContent>
                <Typography variant="span">
                  Are you sure you want to DEACTIVATE {campaignLeadSources.name}
                  ?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="contained" color="error">
                  Deactivate
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
