import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  TextField,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import GridDeleteIcon from "@mui/icons-material/Delete";
import withAuth from "../../../../../../Hoc/withAuth";

const Notes = ({ evaluation, updateEvaluation, user }) => {
  const [notes, setNotes] = useState("");
  const [openNotes, setOpenNotes] = useState(false);

  let allNotes = evaluation.notes
    ? evaluation.notes.sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];

  const handleNotes = () => {
    const currentNotes = evaluation.notes ? evaluation.notes : [];
    const evalData = {
      id: evaluation.id,
      notes: [
        ...currentNotes,
        {
          id: uuidv4(),
          note: notes,
          date: new Date(),
          createdBy: user.email,
        },
      ],
    };
    updateEvaluation.mutate(evalData);
    setNotes("");
  };

  const handleOpenNotesClick = () => {
    setOpenNotes(!openNotes);
  };

  const handleDeleteNote = (index) => {
    const updatedNotes = evaluation.notes.filter((_, i) => i !== index);
    const evalData = {
      id: evaluation.id,
      notes: updatedNotes,
    };
    updateEvaluation.mutate(evalData);
  };

  return (
    <Card sx={{ mt: 1 }} variant="outlined">
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">General Notes</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Enter Notes"
              fullWidth
              multiline
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              sx={{
                height: "100%",
              }}
              variant="contained"
              color="secondary"
              onClick={handleNotes}>
              Save
            </Button>
          </Grid>
          <Grid item xs={12}>
            {allNotes &&
              allNotes.length > 0 &&
              allNotes[0] &&
              allNotes[0].createdBy && (
                <>
                  <List
                    sx={{
                      py: 0,
                      width: "100%",
                      borderRadius: 2,
                      border: "1px solid",
                      borderColor: "divider",
                      backgroundColor: "background.paper",
                    }}>
                    <ListItem>
                      <ListItemText primary={`Note 1: ${allNotes[0]?.note}`} />
                      <Typography variant="caption">
                        Last note added by {allNotes[0].createdBy}
                      </Typography>
                    </ListItem>
                  </List>
                </>
              )}
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth color="inherit" onClick={handleOpenNotesClick}>
              {openNotes ? "Collapse Notes" : "View all Notes"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            {openNotes && (
              <Stack spacing={1}>
                {evaluation?.notes?.length > 0 ? (
                  evaluation.notes.map((note, index) => (
                    <Box
                      key={note.id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <List
                        sx={{
                          py: 0,
                          width: "100%",
                          borderRadius: 2,
                          border: "1px solid",
                          borderColor: "divider",
                          backgroundColor: "background.paper",
                        }}>
                        <ListItem>
                          <ListItemText
                            primary={`Note ${index + 1}: ${note?.note}`}
                          />
                          <Typography variant="caption">
                            Added by {note.createdBy}
                          </Typography>
                          <GridDeleteIcon
                            color="warning"
                            sx={{ cursor: "pointer", ml: 2 }}
                            onClick={() => handleDeleteNote(index)}
                          />
                        </ListItem>
                      </List>
                    </Box>
                  ))
                ) : (
                  <Typography>No notes available</Typography>
                )}
              </Stack>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withAuth(Notes);
