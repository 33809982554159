import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import InfoIcon from "@mui/icons-material/Info";

import { Stack } from "@mui/system";

const PlanningGroupsForm = ({ month, updateMonth }) => {
  const [fieldWidth] = React.useState(4);

  return (
    <div>
      <Formik
        initialValues={{
          id: month.id,
          campaign_standard_hours: 6,
          month_group_id: month.month_group_id,
          type: month.type,
          month_name: month.month_name,
          lead_no: month.lead_no || 0,
          contact_ratio: month.contact_ratio || 0,
          target_sales_per_contact_ratio:
            month.target_sales_per_contact_ratio || 0,
          target_sales_per_hour: month.target_sales_per_hour || 0,
          forecast_average_premium: month.forecast_average_premium || 0,
          ci_per_sale: month.ci_per_sale || 0,
          pricing_model: month.pricing_model || 0,
          pricing_model_type: month.pricing_model_type || 0,
          qa_rate: month.qa_rate || 0,
          business_income_per_hour_option:
            month.business_income_per_hour_option || false,
          business_income_for_hours: month.business_income_for_hours || 120,
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateMonth.mutate(values);
        }}>
        {({ values, dirty, setFieldValue }) => {
          let contacts_per_month =
            values.lead_no * (values.contact_ratio / 100);

          let target_sales =
            contacts_per_month * (values.target_sales_per_contact_ratio / 100);

          let target_sales_per_leads_ratio =
            (target_sales / values.lead_no) * 100;

          let target_hours = target_sales * values.target_sales_per_hour;

          let forecast_business_income_per_sale = values.pricing_model
            ? values.forecast_average_premium * values.pricing_model
            : values.forecast_average_premium;

          let forecast_business_income_for_hours = (values.lead_no / 10) * 120;

          let forecast_theoretical_business_income =
            target_sales * forecast_business_income_per_sale;

          let forecast_commission_income = target_sales * values.ci_per_sale;

          let qa_target = target_sales * (values.qa_rate / 100);

          return (
            <Form>
              {/* <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setDisplayType("type1");
                  }}>
                  Type 1
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    setDisplayType("type2");
                  }}>
                  Type 2
                </Button>

                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                  <Box sx={{ width: 320 }}>
                    <Typography id="non-linear-slider" gutterBottom>
                      Field Width : {fieldWidth}
                    </Typography>
                    <Slider
                      fullWidth
                      value={fieldWidth}
                      onChange={(e, value) => {
                        setFieldWidth(value);
                      }}
                      step={1}
                      marks
                      min={1}
                      max={12}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                    />
                  </Box>
                </Stack>
              </Stack> */}

              <>
                <Grid container spacing={2}>
                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      size="small"
                      type="number"
                      label="STANDARD CAMPAIGN HOURS PER DAY"
                      name="campaign_standard_hours"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">H:</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      size="small"
                      type="number"
                      label="PLANNED LEADS FOR THE MONTH"
                      name="lead_no"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">No: </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      type="number"
                      size="small"
                      label="CONTACT RATIO"
                      name="contact_ratio"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      type="number"
                      size="small"
                      label="TARGET SALES PER CONTACT RATIO"
                      name="target_sales_per_contact_ratio"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Enter the target sales made per individual contacted as a %.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      type="number"
                      size="small"
                      label="TARGET SALES PER HOUR "
                      name="target_sales_per_hour"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">No:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Enter the planned sales per hour.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      type="number"
                      size="small"
                      label="FORECAST AVERAGE PREMIUM EXCL. VAT"
                      name="forecast_average_premium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Enter the forecast average premium per month per sale.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      size="small"
                      name="ci_per_sale"
                      label="CI PER SALE"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      size="small"
                      name="qa_rate"
                      label="QA RATE"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="QA RATE">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      size="small"
                      name="pricing_model"
                      label="Pricing model XP"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Enter the x P as a numeral if the pricing model is a x P model. DO NOT enter anything if the pricing model is NOT a x P model.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.business_income_per_hour_option}
                          onChange={(e) => {
                            setFieldValue(
                              "business_income_per_hour_option",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Forecast business income for hours option"
                    />
                  </Grid>

                  {values.business_income_per_hour_option && (
                    <Grid item xs={fieldWidth}>
                      <TextfieldWrapper
                        size="small"
                        name="business_income_for_hours"
                        label="Forecast business income amount"
                        type="number"
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider sx={{ mb: 3, mt: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      label="Contacts per month"
                      type="number"
                      name="ContactsPerMonth"
                      value={contacts_per_month.toFixed(2)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">No:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Contacts per month = planned number of contacts in the month x contact ratio.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      label="TARGET SALES"
                      type="number"
                      name="ContactsPerMonth"
                      value={target_sales.toFixed(2)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">No:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Target sales = contacts per month x target sales per contact ratio.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      label="TARGET SALES PER LEADS RATIO"
                      disabled
                      name="TARGET SALES PER LEADS RATIO"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Enter the target sales made per individual contacted as a %.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={target_sales_per_leads_ratio.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      label="TARGET HOURS"
                      type="number"
                      name="TARGET HOURS"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">H:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Target hours = target sales x target sales per hour.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={target_hours.toFixed(2)}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      label="FORECAST BUSINESS INCOME PER SALE"
                      type="number"
                      name="FORECAST BUSINESS INCOME PER SALE"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="If a x P model, BI = average premium x (x P) factor. If a % API model, BI = (average premium x 12) x % API.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={forecast_business_income_per_sale.toFixed(2)}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      label="FORECAST THEORETICAL BUSINESS INCOME"
                      type="number"
                      name="FORECAST THEORETICAL BUSINESS INCOME"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="FORECAST THEORETICAL BUSINESS INCOME">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={forecast_theoretical_business_income.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      label="FORECAST COMMISSION INCOME"
                      type="number"
                      name="FORECAST COMMISSION INCOME"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip
                            title="FORECAST COMMISSION INCOME = 
                            target sales x CI per sale.
                            ">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={forecast_commission_income.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      label="QA Target"
                      type="number"
                      name="QA Target"
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            title="FORECAST COMMISSION INCOME = 
                            target sales x CI per sale.
                            ">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={qa_target.toFixed(2)}
                    />
                  </Grid>

                  {values.business_income_per_hour_option && (
                    <Grid item xs={fieldWidth}>
                      <TextField
                        disabled
                        size="small"
                        fullWidth
                        label="Forecast Business Income for Hours"
                        type="number"
                        name="Forecast Business Income for Hours"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R:</InputAdornment>
                          ),
                          endAdornment: (
                            <Tooltip title="If a x P model, BI = average premium x (x P) factor. If a % API model, BI = (average premium x 12) x % API.">
                              <InfoIcon />
                            </Tooltip>
                          ),
                        }}
                        value={forecast_business_income_for_hours.toFixed(2)}
                      />
                    </Grid>
                  )}
                </Grid>
              </>

              <Stack sx={{ mt: 4 }}>
                {dirty && (
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                )}
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PlanningGroupsForm;
