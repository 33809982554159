import React, { useState } from "react";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { Stack, TextField, Box, LinearProgress, Alert } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../../Hoc/withAuth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { format, startOfDay } from "date-fns";
import { useParams } from "react-router-dom";

const CallCenterAgentReport = ({ user }) => {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(startOfDay(new Date()));
  const { id } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: AllEvaluations, isLoading } = useQuery(
    ["getAllEvaluations", startDate, endDate],
    async () => {
      const url = new URL(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/evaluations/${id}/CCEvals`
      );

      if (startDate && endDate) {
        url.searchParams.append("startDate", format(startDate, "yyyy-MM-dd"));
        url.searchParams.append("endDate", format(endDate, "yyyy-MM-dd"));
      }

      const response = await axios.get(url.toString(), config);
      return response?.data?.data || [];
    },
    {
      enabled: !!startDate && !!endDate,
    }
  );

  const columns = [
    {
      field: "call_center_agent",
      headerName: "Call Center Agent Name",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "From",
      width: 150,
      valueFormatter: (params) =>
        new Date(params.value).toLocaleDateString("en-GB"),
    },
    {
      field: "updatedAt",
      headerName: "To",
      width: 150,
      valueFormatter: (params) =>
        new Date(params.value).toLocaleDateString("en-GB"),
    },
    {
      field: "evaluations",
      headerName: "Total Evaluations",
      width: 150,
    },
    {
      field: "busy",
      headerName: "Busy",
      width: 150,
    },
    {
      field: "completed",
      headerName: "Completed",
      width: 150,
    },
    {
      field: "failed",
      headerName: "Failed",
      width: 150,
    },
    {
      field: "passrate",
      headerName: "Pass Rate(%)",
      width: 150,
      valueFormatter: (params) => params.value + "%",
    },
    {
      field: "failrate",
      headerName: "Failure Rate(%)",
      width: 150,
      valueFormatter: (params) => params.value + "%",
    },
  ];

  return (
    <Stack spacing={2} mt={2}>
      <Box display="flex" alignItems="center" mb={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
              },
            }}
          />
          <Box mx={2}>to</Box>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
              },
            }}
          />
        </LocalizationProvider>
      </Box>

      {isLoading ? (
        <LinearProgress />
      ) : AllEvaluations.length > 0 ? (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPremium
            rows={AllEvaluations}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            disableSelectionOnClick
            density="compact"
          />
        </div>
      ) : (
        <Alert severity="info">No Data Found</Alert>
      )}
    </Stack>
  );
};

export default withAuth(CallCenterAgentReport);
