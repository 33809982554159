import * as React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import ScoreCardSelector from "../../../../../Admin/components/CallbiSelector";
import { Divider, Typography } from "@mui/material";
import * as Yup from "yup";

export default function CreateCampaignForm({ createInboundCampaign }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Create new QA Queue
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create QA Inbound Queue</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: "",
              description: "",
              type: "",
              callbi_scorecard: "",
              minEvaluations: "",
              maxEvaluations: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
              description: Yup.string().required("Required"),
              type: Yup.string().required("Required"),

              minEvaluation: Yup.number().required("Required"),
              maxEvaluation: Yup.number().required("Required"),
            })}
            onSubmit={(values) => {
              console.log(values);
              createInboundCampaign.mutate(values);
              handleClose();
            }}>
            {() => {
              return (
                <Form>
                  <Stack sx={{ py: 2 }} spacing={2}>
                    <TextfieldWrapper name="name" label="Queue Name" />
                    <TextfieldWrapper name="description" label="Description" />
                    <TextfieldWrapper name="type" label="Type" />

                    <Divider />

                    <Typography sx={{ my: 1 }} variant="h6" color="GrayText">
                      Queue Settings
                    </Typography>

                    <ScoreCardSelector name="callbi_scorecard" />

                    <TextfieldWrapper
                      name="minEvaluations"
                      label="Minimum amount of evaluation allocated to Qa consultant at a time"
                    />
                    <TextfieldWrapper
                      name="maxEvaluations"
                      label="Maximum amount of evaluation allocated to Qa consultant at a time"
                    />
                  </Stack>

                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}>
                      cancel
                    </Button>
                    <Button variant="contained" type="submit" color="secondary">
                      save
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
