import {
  // AppBar,
  Card,
  CardActionArea,
  Grid,
  // Tab,
  // Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../Hoc/withAuth";
import CampaignIcon from "@mui/icons-material/Campaign";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import SegmentIcon from "@mui/icons-material/Segment";
import GroupsIcon from "@mui/icons-material/Groups";
// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
// import QueryStatsIcon from "@mui/icons-material/QueryStats";

const CallCenter = ({ user, modules }) => {
  const navigate = useNavigate();

  if (
    modules &&
    modules.includes("call-center-admin") &&
    !user.userType === "admin"
  ) {
    navigate("/");
  }

  const { pathname } = useLocation();

  const currentPath = pathname.split("/")[3];

  const Paths = [
    {
      name: "Campaigns",
      location: "campaigns",
      icon: <CampaignIcon />,
    },

    {
      name: "Clients",
      location: "clients",
      icon: <PermIdentityIcon />,
    },
    {
      name: "Global Dispositions list",
      location: "dispositions",
      icon: <SegmentIcon />,
    },
    {
      name: "Global Teams",
      location: "teams",
      icon: <GroupsIcon />,
    },
    // {
    //   name: "All Sales",
    //   location: "Sales",
    //   icon: <TrendingUpIcon />,
    // },
    // {
    //   name: "Leads Admin",
    //   location: "LeadsAdmin",
    //   icon: <QueryStatsIcon />,
    // },
    {
      name: "Manage Lead Sources",
      location: "leadSources",
      icon: <AddLocationAltIcon />,
    },
  ];

  return (
    <>
      <PageHeader
        title="Call Center Admin"
        subheader="Manage Call Center Campaigns"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Manage Call Center",
            href: "/CDA/callCenter",
          },
        ]}
      />

      <Grid container spacing={2}>
        <Grid item xs={2} xl={2}>
          {Paths.map((path, index) => {
            return (
              <Grid key={index} item xs={12}>
                <Card
                  sx={
                    currentPath === path.location
                      ? { borderStyle: "solid", borderColor: "primary.main" }
                      : null
                  }>
                  <CardActionArea
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      py: 5,
                    }}
                    onClick={() => {
                      navigate(`${path.location}`);
                    }}>
                    <Typography color="textPrimary">{path.name}</Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={10} xl={10}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default withAuth(CallCenter);
