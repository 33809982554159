import { Typography, Button, LinearProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import { useMutation, useQuery } from "@tanstack/react-query";
import withAuth from "../../../../../Hoc/withAuth";
import axios from "axios";
import DeleteGroup from "./Components/DeleteGroup";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

const CampaignPlanningMain = ({ user }) => {
  const navigate = useNavigate();

  const token = user.token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const Groups = useQuery({
    queryKey: ["groups"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/targets/groups`,
        config
      ),
  });

  const createGroup = useMutation({
    mutationKey: ["createGroup"],
    mutationFn: async (values) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/targets/groups`,
        values,
        config
      ),
    onSuccess: (data) => {
      navigate(`/CDA/CallCenter/CampaignPlanningMain/${data?.data?.data?.id}`);
    },
  });

  const deleteGroup = useMutation({
    mutationKey: ["deleteGroup"],
    mutationFn: async (id) =>
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/targets/groups/${id}`,
        config
      ),
    onSuccess: () => {
      Groups.refetch();
    },
  });

  const rows = Groups?.data?.data?.data;

  console.log("rows", rows);

  let columns = [
    {
      field: "View Group",
      headerName: "View Group",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              navigate(`/CDA/CallCenter/CampaignPlanningMain/${params.row.id}`);
            }}>
            View Group
          </Button>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "active",
      headerName: "Active",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,
    },
    {
      field: "DeleteGroup",
      headerName: "DeleteGroup",
      width: 200,

      renderCell: (params) => {
        return <DeleteGroup deleteGroup={deleteGroup} group={params.row} />;
      },
    },
  ];

  if (Groups.isLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <PageHeader
        title="Campaign Planning"
        subheader="Plan your campaigns here"
        breadcrumbs={[
          {
            title: "Home",
            href: "/",
          },
          {
            title: "Campaign Planning",
            href: "/CDA/CallCenter/CampaignPlanningMain",
          },
        ]}
      />

      <Typography>Campaign Planning Main</Typography>

      <Stack direction="row" justifyContent="flex-end">
        {createGroup.isLoading ? <LinearProgress /> : null}
        <CreateGroup createGroup={createGroup} />
      </Stack>

      <Stack
        sx={{
          height: "80vh",
          width: "100%",
          marginTop: 2,
        }}>
        {rows && rows.length > 0 && (
          <DataGridPremium rows={rows} columns={columns} />
        )}
      </Stack>
      <AlertPopup
        open={deleteGroup.isError}
        severity="error"
        message="Error Deleting Group"
      />
      <AlertPopup
        open={deleteGroup.isSuccess}
        severity="success"
        message="Group Deleted"
      />
    </div>
  );
};

export default withAuth(CampaignPlanningMain);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateGroup({ createGroup }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Create Planning Group
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Create New Group"}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: "",
              description: "",
            }}
            onSubmit={(values) => {
              createGroup.mutate(values);
            }}>
            {() => {
              return (
                <Form>
                  <Stack spacing={2} sx={{ mt: 4 }}>
                    <TextfieldWrapper name="name" label="Name" />
                    <TextfieldWrapper name="description" label="Description" />
                  </Stack>

                  <DialogActions sx={{ mt: 4 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleClose}>
                      Save
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
