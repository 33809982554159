import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid-pro";
import React from "react";
import SelectCampaignToLink from "./SelectCampaignToLink";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const LinkedCampaigns = ({
  id,
  campaigns,
  linkCampaignToGroup,
  unLinkCampaignFromGroup,
}) => {
  return (
    <Accordion>
      <AccordionSummary
        sx={{
          borderColor: "warning.main",
          borderWidth: 1,
          borderStyle: "solid",
        }}
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header">
        <Typography variant="h6">
          CC Campaigns Linked to this group NO : {campaigns.length}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <SelectCampaignToLink
            id={id}
            campaigns={campaigns}
            linkCampaignToGroup={linkCampaignToGroup}
          />

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Campaign ID</TableCell>
                  <TableCell>Campaign Name</TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell>unlink</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaigns &&
                  campaigns.length > 0 &&
                  campaigns.map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        {row?.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row?.cc_client.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <UnlinkCampaign
                          unLinkCampaignFromGroup={unLinkCampaignFromGroup}
                          campaign={row}
                          id={id}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default LinkedCampaigns;

function UnlinkCampaign({ campaign, unLinkCampaignFromGroup, id }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnlink = () => {
    unLinkCampaignFromGroup.mutate({ campaignId: campaign.id, groupId: id });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" color="error" onClick={handleClickOpen}>
        <DeleteForeverIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Unlink Campaign</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to unlink this campaign from the group?
            {campaign?.id} - {campaign?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={handleUnlink}
            autoFocus
            color="error"
            variant="contained">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
