import React from "react";

import { useState } from "react";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import withAuth from "../../../../../../Hoc/withAuth";
import { Autocomplete, Button, Chip, Stack, TextField } from "@mui/material";

const SelectCampaignToLink = ({ user, campaigns, id, linkCampaignToGroup }) => {
  const [page] = useState(0);
  const [rowsPerPage] = useState(20);
  const [values] = useState({});
  const token = user.token; // Replace with your token retrieval logic

  let S = values ? "&search=" + values.search : "";
  let P = rowsPerPage ? rowsPerPage : 1000;
  let PA = page ? page : 0;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const query = useQuery({
    queryKey: ["campaigns"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns?pageNumber=${PA}&limit=${P}${S}`,
        config
      ),
  });

  let AllCampaigns =
    query?.data?.data?.data?.rows?.filter(
      (campaign) => !campaigns.find((c) => c.id === campaign.id)
    ) || [];

  const [value, setValue] = React.useState([]);

  const handleSubmit = () => {
    let subData = {
      campaignIds: value.map((v) => v.id),
      targetGroup: id,
    };

    console.log(subData);

    linkCampaignToGroup.mutate(subData);

    setValue([]);
  };

  if (query?.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack direction="row" spacing={2}>
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        options={AllCampaigns}
        getOptionLabel={(option) => option.name}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <Chip key={key} label={option.name} {...tagProps} />;
          })
        }
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Campaigns to Link"
            placeholder="Linked Campaigns"
          />
        )}
      />

      {value && value.length > 0 && (
        <Button onClick={handleSubmit} variant="contained">
          Save
        </Button>
      )}
    </Stack>
  );
};

export default withAuth(SelectCampaignToLink);
