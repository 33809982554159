import { Button } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

const StartPlanningButton = ({ bulkCreatePlanningMonths, month }) => {
  function getThreeMonths(month) {
    const monthFormat = "MMMM"; // format for full month names (e.g., "January")
    const startMonthIndex = dayjs(month, monthFormat).month(); // get the index of the start month

    const months = [];
    for (let i = 0; i < 3; i++) {
      const monthName = dayjs()
        .month(startMonthIndex + i)
        .format(monthFormat);
      months.push(monthName);
    }

    return months;
  }

  const handlePlanningMonthsCreate = () => {
    const months = getThreeMonths(month.month);

    let data = [
      {
        month_group_id: month.id,
        type: "Forecast0",
        month_name: months[0],
      },
      {
        month_group_id: month.id,
        type: "Forecast1",
        month_name: months[1],
      },
      {
        month_group_id: month.id,
        type: "Forecast2",
        month_name: months[2],
      },
    ];

    bulkCreatePlanningMonths.mutate(data);
  };

  return (
    <Button
      variant="contained"
      sx={{ mt: 4 }}
      onClick={() => handlePlanningMonthsCreate()}>
      Start Planning for {month.month}
    </Button>
  );
};

export default StartPlanningButton;
