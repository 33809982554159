import { Grid } from "@mui/material";
import React from "react";
import FeatureCard from "../../Components/Containers/FeatureCard";
import withAuth from "../../Hoc/withAuth";
import SupportAgentTwoToneIcon from "@mui/icons-material/SupportAgentTwoTone";
import HeadsetTwoToneIcon from "@mui/icons-material/HeadsetTwoTone";
import PollIcon from "@mui/icons-material/Poll";
import PollTwoToneIcon from "@mui/icons-material/PollTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import EngineeringTwoToneIcon from "@mui/icons-material/EngineeringTwoTone";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ModeStandbyTwoToneIcon from "@mui/icons-material/ModeStandbyTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import HotTubIcon from "@mui/icons-material/HotTub";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

const CDADashBoard = ({ user, modules, companyDetails }) => {
  console.log("Current Logged in user modules", modules);

  if (
    companyDetails?.company === "CDA Solutions" ||
    user.userType === "admin"
  ) {
    return (
      <div>
        <Grid container spacing={1} mb={1}>
          {(modules && modules.includes("call-center-admin")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={ManageAccountsTwoToneIcon}
                title="Call Center Admin"
                link="/CDA/callCenter/campaigns"
              />
            </Grid>
          ) : (
            <></>
          )}

          {modules && modules.includes("qa_inbound_manager") ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={AssignmentIndIcon}
                title="Inbound QA Manager"
                link="/CDA/inbound/manager"
              />
            </Grid>
          ) : (
            <></>
          )}

          {modules && modules.includes("inbound-qa-user") ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={SpatialAudioOffIcon}
                title="Inbound QA User"
                link="/CDA/inbound/user"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("inbound-qa-admin")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={AdminPanelSettingsIcon}
                title="Inbound QA Admin"
                link="/CDA/inbound/admin"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("call-center-admin")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={TrackChangesIcon}
                title="Campaign Planning"
                link="/CDA/CallCenter/CampaignPlanningMain"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("call-center-manager")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={ModeStandbyTwoToneIcon}
                title="Call Center Targets"
                link="/CDA/CallCenter/TargetsMain/UserList"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("call-center-team-leader")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={HotTubIcon}
                title="Call Center Team Leader"
                link="/CDA/CallCenter/TeamLeader/CampaignList"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("call-center-team-leader")) ||
          modules.includes("call-center-user") ||
          modules.includes("call-center-manager") ||
          modules.includes("call-center-admin") ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={ModelTrainingIcon}
                title="Call Center Coaching"
                link="/CDA/CallCenter/Coaching/ListOfScoreCards"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("call-center-manager")) ||
          modules.includes("call-center-team-leader") ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={GroupsIcon}
                title="Call Center Attendance"
                link="/CDA/CallCenter/Attendance"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("call-center-user")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={SupportAgentTwoToneIcon}
                title="Call Center"
                link="/CDA/AgentHome/CampaignList"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("qa")) || user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={HeadsetTwoToneIcon}
                title="QA"
                link="/CDA/QaAgentHome"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("qa_admin")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={EngineeringTwoToneIcon}
                title="QA Admin Console"
                link="/CDA/QaConsole/QaQueues"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("survey")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={PollIcon}
                title="Surveys"
                link="/CDA/survey"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("survey_admin")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={PollTwoToneIcon}
                title="Surveys Admin"
                link="/CDA/surveyAdmin"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(modules && modules.includes("reports")) ||
          user.userType === "admin" ? (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FeatureCard
                company="CDA Solutions"
                Icon={QueryStatsIcon}
                title="Reports"
                link="/reports"
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </div>
    );
  } else {
    return <></>;
  }
};

export default withAuth(CDADashBoard);

// {/* {(modules && modules.includes("crm")) || user.userType === "admin" ? (
//         <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
//           <FeatureCard
//             company="CDA Solutions"
//             Icon={AppRegistrationIcon}
//             title="CRM"
//             link="/crm"
//           />
//         </Grid>
//       ) : (
//         <></>
//       )} */}

// {/* {(modules && modules.includes("call-center-admin")) ||
// user.userType === "admin" ? (
//   <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
//     <FeatureCard
//       company="CDA Solutions"
//       Icon={PointOfSaleIcon}
//       title="Sale Orders"
//       link="/CDA/SalesOrders"
//     />
//   </Grid>
// ) : (
//   <></>
// )} */}

// {/* {(modules && modules.includes("questionnaire")) ||
// user.userType === "admin" ? (
//   <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
//     <FeatureCard
//       company="CDA Solutions"
//       Icon={SchoolIcon}
//       title="Questionnaire"
//       link="/CDA/UserQuestionnaires"
//     />
//   </Grid>
// ) : (
//   <></>
// )} */}

// {/* {(modules && modules.includes("questionnaire_admin")) ||
// user.userType === "admin" ? (
//   <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
//     <FeatureCard
//       company="CDA Solutions"
//       Icon={SchoolTwoToneIcon}
//       title="Questionnaire Admin"
//       link="/CDA/questionnaireAdmin"
//     />
//   </Grid>
// ) : (
//   <></>
// )} */}

// {/* {(modules && modules.includes("avm")) || user.userType === "admin" ? (
//           <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
//             <FeatureCard
//               company="CDA Solutions"
//               Icon={RecordVoiceOverTwoToneIcon}
//               title="AVM Management"
//               link="/CDA/avm_management"
//             />
//           </Grid>
//         ) : (
//           <></>
//         )} */}
//         {/* {(modules && modules.includes("santam")) ||
//         user.userType === "admin" ? (
//           <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
//             <FeatureCard
//               company="CDA Solutions"
//               Icon={BeachAccessIcon}
//               title="Santam"
//               link="/santam"
//             />
//           </Grid>
//         ) : (
//           <></>
//         )} */}

//  {/* {((modules && modules.includes("oe-manager")) ||
//       user.userType === "admin") && (
//       <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
//         <FeatureCard
//           company="CDA Solutions"
//           Icon={PeopleAltIcon}
//           title="Onboard Employees - Managers"
//           link="onboarding/manager/requisition"
//         />
//       </Grid>
//     )} */}

//     {/* {((modules && modules.includes("oe-hr")) ||
//       user.userType === "admin") && (
//       <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
//         <FeatureCard
//           company="CDA Solutions"
//           Icon={PeopleAltIcon}
//           title="Onboard Employees - HR"
//           link="/onboarding/hr/employees"
//         />
//       </Grid>
//     )} */}

//     {/* {((modules && modules.includes("oe-ceo")) ||
//       user.userType === "admin") && (
//       <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
//         <FeatureCard
//           company="CDA Solutions"
//           Icon={PeopleAltIcon}
//           title="Onboard Employees - CEO"
//           link="/onboarding/ceo/employees"
//         />
//       </Grid>
//     )} */}
