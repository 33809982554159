import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const EvaluationsCard = ({ evaluation }) => {
  console.log(evaluation);
  return (
    <Card>
      <CardHeader
        title={
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Typography color="GrayText" variant="h6">
                Unique Evaluation Id
              </Typography>
              <Typography variant="h6">{evaluation.unique_id}</Typography>
            </Stack>
          </Stack>
        }
        action={
          <Chip
            label={evaluation.status}
            color={
              evaluation.status === "Completed"
                ? "secondary"
                : evaluation.status === "Busy"
                ? "warning"
                : evaluation.status === "New" ||
                  evaluation.status === "Re-Evaluate"
                ? "primary"
                : evaluation.status === "Failed"
                ? "error"
                : "info"
            }
          />
        }
      />
      <CardContent>
        <Grid container>
          <Grid item xs={2}>
            <Stack direction="column" spacing={2}>
              <Typography color="GrayText">Disposition</Typography>
              <Typography color="GrayText">Evaluation type</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack direction="column" spacing={2}>
              <Typography>{evaluation.disposition}</Typography>
              <Typography>{evaluation.evaluation_type}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Stack direction="column" spacing={2}>
              <Typography color="GrayText">QA Analyst</Typography>
              <Typography color="GrayText">User being Evaluated</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack direction="column" spacing={2}>
              <Typography>{evaluation.evaluation_qa_users?.name}</Typography>
              <Typography>{evaluation.evaluation_users?.name}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EvaluationsCard;
