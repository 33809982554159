import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Slide,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Form, Formik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import withAuth from "../../../../../Hoc/withAuth";
import LinkedCampaigns from "./Components/LinkedCampaigns";
import PlanningGroupsForm from "./Components/PlanningGroupsForm";
import StartPlanningButton from "./Components/StartPlanningButton";

const PlanningMonthsGroups = ({ user }) => {
  const { id } = useParams();

  const token = user.token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const Group = useQuery({
    queryKey: ["group", id],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/targets/groups/${id}`,
        config
      ),
  });

  const linkCampaignToGroup = useMutation({
    mutationKey: ["linkCampaignToGroup", id],
    mutationFn: async (values) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/targets/groupLinks`,
        values,
        config
      ),
    onSuccess: () => {
      Group.refetch();
    },
  });

  const unLinkCampaignFromGroup = useMutation({
    mutationKey: ["unLinkCampaignFromGroup", id],
    mutationFn: async (values) =>
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/targets/groupLinks/${id}/campaign/${values.campaignId}`,
        config
      ),
    onSuccess: () => {
      Group.refetch();
    },
  });

  const getGroupMonths = useQuery({
    queryKey: ["groupMonths", id],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/targets/groups/${id}/months`,
        config
      ),
  });

  const makeGroupMonth = useMutation({
    mutationKey: ["makeGroupMonth", id],
    mutationFn: async (values) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/targets/groups/${id}/months`,
        values,
        config
      ),
    onSuccess: () => {
      getGroupMonths.refetch();
    },
  });

  const bulkCreatePlanningMonths = useMutation({
    mutationKey: ["bulkCreatePlanningMonths", id],
    mutationFn: async (values) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/targets/groups/${id}/months/bulk`,
        values,
        config
      ),
    onSuccess: () => {
      getGroupMonths.refetch();
    },
  });

  const updateMonth = useMutation({
    mutationKey: ["updateMonth"],
    mutationFn: async (values) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/targets/groups/${id}/months/${values.id}`,
        values,
        config
      ),
  });

  const campaigns = Group?.data?.data?.data?.campaigns || [];

  let groupData = Group?.data?.data?.data;

  const [selected, setSelected] = React.useState({});

  if (Group.isLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <PageHeader
        title={groupData?.name}
        subheader={groupData?.description}
        breadcrumbs={[
          {
            title: "Home",
            href: `/`,
          },
          {
            title: "Campaign Planning Main",
            href: `/CDA/CallCenter/CampaignPlanningMain`,
          },
          {
            title: `${groupData?.name}`,
            href: `/CDA/CallCenter/CampaignPlanningMain/Group`,
          },
        ]}
      />

      <Stack>
        <Typography variant="h6">Planning Months Groups</Typography>
      </Stack>
      <LinkedCampaigns
        campaigns={campaigns}
        id={id}
        linkCampaignToGroup={linkCampaignToGroup}
        unLinkCampaignFromGroup={unLinkCampaignFromGroup}
      />
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 4 }}>
        <CreateMonthGroup
          id={id}
          makeGroupMonth={makeGroupMonth}
          getGroupMonths={getGroupMonths}
        />
      </Stack>

      <Stack sx={{ mt: 4 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2">
              Select Month to start planning for the next 3 months
            </Typography>
          </Grid>
          {getGroupMonths?.data?.data?.data.map((month, index) => (
            <Grid item xs={1} key={index}>
              <Card>
                <CardActionArea
                  onClick={() => setSelected(month)}
                  sx={{
                    bgcolor: selected.id === month.id ? "primary.main" : "",
                    color: selected.id === month.id ? "white" : "",
                  }}>
                  <Typography align="center" sx={{ m: 1 }}>
                    {month.month}
                  </Typography>
                  <Typography align="center" sx={{ m: 1 }}>
                    {month.year}
                  </Typography>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>

      <Stack sx={{ mt: 4 }}>
        {selected?.month && (
          <PlanningGroups
            updateMonth={updateMonth}
            bulkCreatePlanningMonths={bulkCreatePlanningMonths}
            monthGroup={getGroupMonths?.data?.data?.data
              .filter((month) => month.id === selected.id)
              .pop()}
          />
        )}
      </Stack>

      <AlertPopup
        open={updateMonth.isError}
        severity="error"
        message="Something went wrong. Please try again."
      />
      <AlertPopup
        open={updateMonth.isSuccess}
        severity="success"
        message="Month updated successfully."
      />
      <AlertPopup
        open={linkCampaignToGroup.isError}
        severity="error"
        message="Something went wrong. Please try again."
      />
      <AlertPopup
        open={unLinkCampaignFromGroup.isError}
        severity="error"
        message="Something went wrong. Please try again."
      />

      <AlertPopup
        open={linkCampaignToGroup.isSuccess}
        severity="success"
        message="Campaign linked successfully."
      />
      <AlertPopup
        open={unLinkCampaignFromGroup.isSuccess}
        severity="success"
        message="Campaign unlinked successfully."
      />
      <AlertPopup
        open={makeGroupMonth.isSuccess}
        severity="success"
        message="Month Group Create Successfully"
      />
      <AlertPopup
        open={makeGroupMonth.isError}
        severity="error"
        message="Something went wrong. Please try again."
      />
    </div>
  );
};

export default withAuth(PlanningMonthsGroups);

const PlanningGroups = ({
  monthGroup,
  bulkCreatePlanningMonths,
  updateMonth,
}) => {
  // const [viewCampaignStatistics, setViewCampaignStatistics] =
  //   React.useState(false);

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6">Planning For {monthGroup.month}</Typography>
        </CardContent>
      </Card>

      {/* <Button
        onClick={() => setViewCampaignStatistics(!viewCampaignStatistics)}>
        View CAMPAIGN STATISTICS
      </Button> */}

      {/* {viewCampaignStatistics && <CampaignStatistics monthGroup={monthGroup} />} */}

      <Stack>
        {monthGroup?.cc_campaign_planning_months &&
        monthGroup?.cc_campaign_planning_months?.length > 0 ? (
          monthGroup?.cc_campaign_planning_months
            ?.sort((a, b) => {
              const extractNumber = (str) =>
                parseInt(str.replace("Forecast", ""), 10);
              return extractNumber(a.type) - extractNumber(b.type);
            })
            .map((month, index) => (
              <Card key={index}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 4 }}>
                    {month?.month_name} - {month?.type}
                  </Typography>
                  <PlanningGroupsForm updateMonth={updateMonth} month={month} />
                </CardContent>
              </Card>
            ))
        ) : (
          <StartPlanningButton
            bulkCreatePlanningMonths={bulkCreatePlanningMonths}
            month={monthGroup}
          />
        )}
      </Stack>
    </>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateMonthGroup({ id, makeGroupMonth, getGroupMonths }) {
  const [open, setOpen] = React.useState(false);

  let createdMonths = getGroupMonths?.data?.data?.data.map(
    (month) => month.month
  );

  let months = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const create = (data) => {
    makeGroupMonth.mutate(data);
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={handleClickOpen}>
        Start Planning
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Create New Month Group"}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              month: "",
              year: 2025,
              target_group_id: id,
            }}
            onSubmit={(values) => {
              create(values);
            }}>
            {() => {
              return (
                <Form>
                  <Stack spacing={2} sx={{ mt: 4 }}>
                    <SelectWrapper
                      name="month"
                      label="Select Month"
                      options={
                        createdMonths && createdMonths.length > 0
                          ? months.filter(
                              (month) => !createdMonths.includes(month.value)
                            ) || months
                          : months
                      }
                    />
                    <SelectWrapper
                      name="year"
                      label="Select Year"
                      options={[
                        { value: 2021, label: "2021" },
                        { value: 2022, label: "2022" },
                        { value: 2023, label: "2023" },
                        { value: 2024, label: "2024" },
                        { value: 2025, label: "2025" },
                        { value: 2026, label: "2026" },
                      ]}
                    />
                  </Stack>

                  <DialogActions sx={{ mt: 4 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleClose}>
                      Save
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
